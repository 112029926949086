import gql from 'graphql-tag'

export const EMAIL_INQUIRY = gql`
	mutation email_inquiry (
		$to: String,
		$name: String!,
		$company: String,
		$phone: String!,
		$email: String!,
		$title: String!,
		$details: String!,
	) {
		email_inquiry (
			to: $to,
      name: $name,
      company: $company,
      phone: $phone,
      email: $email,
      title: $title,
      details: $details,
		)
	}
`

export const EMAIL_SIGNUP_REQUEST = gql`
	mutation email_signup_request (
		$contact_type: String!,
		$name: String!,
		$address: String!,
		$email: String!,
		$phone: String!,
		$name_en: String,
		$address_en: String,
	) {
		email_signup_request (
			contact_type: $contact_type,
			name: $name,
			address: $address,
			name_en: $name_en,
			address_en: $address_en,
			email: $email,
			phone: $phone,
		)
	}
`