<template>
<div>
	<h3  class="pb-3 border-b mb-4">
		<i class="fas fa-bacterium mr-2"></i> Pathogen Bank
	</h3>
</div>
</template>

<script>
export default {
	name: 'pathogen-bank'
}
</script>

<style lang="scss" scoped>
</style>