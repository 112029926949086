<template>
	<div id="orgchart" v-once class="no-wrap">
		<h3  class="pb-3 border-b mb-4">
			<i class="fas fa-sitemap mr-2"></i> ผังองค์กร หน่วยชันสูตรโรคสัตว์กลาง จุฬาฯ
		</h3>

		<div class="d-flex flex-column align-items-center">
			
			<div class="d-flex justify-content-center">
				<div class="d-flex flex-column justify-content-evenly align-items-center align-items-center mx-2 mb-3">
					<div class="label-tag gradient-bg">
						{{ org_chart.boss.title }}
					</div>
					<person-card
						:name="org_chart.boss.name"
						:img="org_chart.boss.img" />
				</div>
			</div>

			<div class="grid-2">
				<div  v-for="person of org_chart.vets"
							:key="person.name"
							class="d-flex flex-column justify-content-evenly align-items-center">
					<div class="label-tag">
						{{ person.title }}  
					</div>
					<person-card
						:name="person.name"
						:img="person.img" />
				</div>
			</div>

			<div class="label-tag mt-4">
				{{ org_chart.lab_employees.title }}
			</div>
			<div class="grid-4">
				<div  v-for="person of org_chart.lab_employees.top"
							:key="person.name"
							class="d-flex flex-column justify-content-evenly align-items-center">
					<div class="label-tag outline">
						{{ person.title }}
					</div>
					<person-card
						:name="person.name"
						:img="person.img" />
				</div>
			</div>

			<div class="grid-3">
				<div  v-for="person of org_chart.lab_employees.bottom"
							:key="person.name"
							class="d-flex flex-column justify-content-evenly align-items-center">
					<div class="label-tag outline">
						{{ person.title }}
					</div>
					<person-card
						:name="person.name"
						:img="person.img" />
				</div>
			</div>

			<div class="grid-3">
				<div  v-for="person of org_chart.employees"
							:key="person.name"
							class="d-flex flex-column justify-content-evenly align-items-center">
					<div class="label-tag">
						{{ person.title }}
					</div>
					<person-card
						:name="person.name"
						:img="person.img" />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import PersonCard from './PersonCard.vue'

export default {
	name: 'org-chart',
	components: {
		'person-card': PersonCard,
	},
	data () {
		return {
			org_chart: {
				boss: {
					title: 'หัวหน้าหน่วยชันสูตรโรคสัตว์กลาง',
					name: 'ผศ.น.สพ.ดร.สว่าง เกษแดงสกลวุฒิ',
					img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/boss.jpg'
				},
				vets: [
					{
						title: 'สัตวแพทย์',
						name: 'น.สพ.รชฎ ตันติเลิศเจริญ',
						img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/vet1.jpg'
					},
					{
						title: 'สัตวแพทย์',
						name: 'สพ.ญ.นภวัลย์ บรรพพงศ์',
						img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/vet2.jpg'
					},
				],
				lab_employees: {
					title: 'เจ้าหน้าที่บริการวิทยาศาสตร์',
					top: [
						{
							title: 'งานไวรัสวิทยา',
							name: 'นางพอจิต ชูใจ',
							img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/lab1.jpg'
						},
						{
							title: 'งานซีรั่มวิทยา',
							name: 'นางผุสดี มาคุ้ม',
							img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/lab2.jpg'
						},
						{
							title: 'งานแบคทีเรีย',
							name: 'นายพงษ์ไทย บุญคำ',
							img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/lab3.jpg'
						},
						{
							title: 'งานอณูชีววิทยา',
							name: 'นางสาวนันทิยา เอี่ยมประภาศ',
							img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/lab4.jpg'
						},
					],
					bottom: [
						{
							title: 'งานโลหิตวิทยา',
							name: 'นางเกษร เอี่ยมละออ',
							img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/lab5.jpg'
						},
						{
							title: 'งานโลหิตวิทยา',
							name: 'นางวันเพ็ญ อดุลยานุภาพ',
							img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/lab6.jpg'
						},
						{
							title: 'งานโลหิตวิทยา',
							name: 'นายกฤษณ์ หัสเขตร์',
							img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/lab7.jpg'
						},
					]
				},
				employees: [
					{
						title: 'พนักงานบริการ',
						name: 'นางสาวศศิกานต์ สินโทต้อม',
						img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/staff1.jpg'
					},
					{
						title: 'เจ้าหน้าที่สำนักงาน (ธุรการ)',
						name: 'นางสาวธันยาภัทร์ พัฒน์สินศิริ',
						img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/staff2.jpg'
					},
					{
						title: 'พนักงานห้องทดลอง',
						name: 'นางสาวจีรภรณ์ สุขชูศรี',
						img: 'https://cuvdl-public.s3-ap-southeast-1.amazonaws.com/people/staff3.jpg'
					},
				]
			}
		}
	}
}
</script>

<style scoped lang="scss">
.grid-main {
	display: grid;
	grid-template-columns: 1fr 1.75fr 1fr;
}
.grid-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 6em;
	grid-row-gap: 1em;
	padding: 1em;
}
.grid-3 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 6em;
	grid-row-gap: 1em;
	padding: 1em;
}
.grid-4 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 6em;
	grid-row-gap: 1em;
	padding: 1em;
}
.label-tag {
	background: $primary;
	color: $light;
	border-radius: $border-radius;
	padding: .1em 0.4em;
	font-weight: bold;
	font-size: 1.3rem;
	text-align: center;
	min-width: 150px;
	&.gradient-bg {
		background: linear-gradient(to right, $primary, $placeholder);
		border-width: 0;
	}
	&::selection {
		color: $primary; /* WebKit/Blink Browsers */
		background: rgba($white, 0.99);
	}
	&::-moz-selection {
		color: $primary; /* Gecko Browsers */
		background: rgba($white, 0.99);
	}
	&.outline {
		box-shadow:0px 0px 0px 2px $primary inset;
		color: $primary;
		background: transparent;
		&::selection {
			background: rgba($primary,.99); /* WebKit/Blink Browsers */
			color: $white;
		}
		&::-moz-selection {
			background: rgba($primary,.99); /* Gecko Browsers */
			color: $white;
		}
	}
}
</style>