<template>
<div id="contact">
	<h3  class="pb-3 border-b mb-4">
		<i class="fas fa-phone mr-2"></i> ติดต่อสอบถาม
	</h3>
	<div class="row pt-4 font-thaisans">
		<div class="col-7 pr-4">
			<div class="form-group row">
				<div class="col-sm-2 col-form-label" />
				<div class="col-sm-10">
					<h2 class="mb-0 font-cu">แบบฟอร์มติดต่อ</h2>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">
					ถึง
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" v-model="to">
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">
					<i class="fas fa-star-of-life" /> ชื่อผู้ติดต่อ
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" v-model="name">
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">
					บริษัท
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" v-model="company">
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">
					<i class="fas fa-star-of-life" /> เบอร์ติดต่อ
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" v-model="phone">
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">
					<i class="fas fa-star-of-life" /> อีเมล
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" v-model="email">
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">
					<i class="fas fa-star-of-life" /> หัวข้อ
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" v-model="title">
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">
					<i class="fas fa-star-of-life" /> รายละเอียด
				</label>
				<div class="col-sm-10">
					<textarea class="form-control" rows="10" v-model="details" />
				</div>
			</div>
			<div class="form-group row">
				<div class="col-sm-2 col-form-label" />
				<div class="col-sm-10">
					<button	type="submit"
									class="btn btn-primary btn-lg btn-block font-cu"
									:disabled="!form_ok || submitting || submitted"
									@click="send_form()">
						<template v-if="submitting">
							<LoadingAnimation />
						</template>
						<template v-else-if="submitted">
							<i class="fas fa-check btn-inner-icon"></i>
							แบบฟอร์มติดต่อ ถูกส่งเรียบร้อยแล้ว
						</template>
						<template v-else>
							<i class="fas fa-paper-plane btn-inner-icon"></i>
							ส่งแบบฟอร์มติดต่อ
						</template>
					</button>
				</div>
			</div>
		</div>
		<div class="col-4 pl-4">
			<h2 class="mb-3 font-cu">ข้อมูลติดต่อ</h2>
			<div class="row py-3 border-b">
				<div class="col-4">
					<h4>
						<i class="fas fa-map-marked-alt mr-3"></i>ที่อยู่:
					</h4>
				</div>
				<div class="col pl-2">
					<h4 class="mb-1">39 ถนนอังรีดูนังต์</h4>
					<h4 class="mb-1">แขวงวังใหม่ เขตพญาไท</h4>
					<h4>กรุงเทพฯ 10330</h4>
				</div>
			</div>
			<div class="row py-3 border-b">
				<div class="col-4">
					<h4>
						<i class="fas fa-phone mr-3"></i>โทร:
					</h4>
				</div>
				<div class="col pl-2">
					<h4 class="mb-1">02-218-9604</h4>
					<h4>02-218-9606</h4>
				</div>
			</div>
			<div class="row py-3 border-b">
				<div class="col-4">
					<h4>
						<i class="fas fa-fax mr-3"></i>แฟ็กซ์:
					</h4>
				</div>
				<div class="col pl-2">
					<h4>02-218-9532</h4>
				</div>
			</div>
			<div class="row py-3 border-b">
				<div class="col-4">
					<h4>
						<i class="fas fa-envelope mr-3"></i>อีเมล:
					</h4>
				</div>
				<div class="col pl-2">
					<h4>cuvdl.thailand@gmail.com</h4>
				</div>
			</div>
		</div>
	</div>
	<Modal  modal-id="contact-success-modal"
					modal-dialog-class="modal-sm modal-dialog-centered">
		<template #modal-header>
			<h3>
				<i class="fas fa-check text-success mr-2"></i>
				ส่งข้อความสำเร็จ
			</h3>
		</template>
		<template #modal-body>
			<h4>เจ้าหน้าที่จะติดต่อกลับไป ทางเบอร์โทรติดต่อหรืออีเมลที่ท่านให้มา</h4>
			<div class="form-row mt-4">
				<div class="form-group col mb-0">
					<button type="button"
									class="btn btn-secondary btn-block"
									data-dismiss="modal">
						กลับไป
					</button>
				</div>
			</div>
		</template>
	</Modal>
</div>
</template>

<script>
import $ from 'jquery'
import { EMAIL_INQUIRY } from '@/graphql/misc'

export default {
	name: 'contact',
	computed: {
		form_ok () {
			return (
				this.name && this.phone && this.email && this.title && this.details
			)
		}
	},
	data () {
		return {
			submitting: false,
			submitted: false,
			to: '',
			name: '',
			company: '',
			phone: '',
			email: '',
			title: '',
			details: '',
		}
	},
	methods: {
		async send_form () {
			this.submitting = true
			try {
				let res = await this.$apollo.mutate({
					mutation: EMAIL_INQUIRY,
					variables: {
						to: this.to || '--',
						name: this.name,
						company: this.company || '--',
						phone: this.phone,
						email: this.email,
						title: this.title,
						details: this.details,
					}
				})
				this.submitting = false
				this.submitted = true
				$('#contact-success-modal').modal('show')
				this.clear_form()
			} catch (err) {
				this.submitting = false
				this.form_error = 'ระบบขัดข้อง กรุณาลองใหม่ในภายหลัง'
			}
		},
		clear_form () {
			this.to = ''
			this.name = ''
			this.company = ''
			this.phone = ''
			this.email = ''
			this.title = ''
			this.details = ''
		}
	},
}
</script>

<style lang="scss" scoped>
#contact {
	margin-bottom: 20vh;
}
</style>